import React from 'react';
import './App.css';
import { Layout, Header, Drawer, Navigation, Content } from 'react-mdl';
import Main from './components/main';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";


function App() {

    const hideToggle = () => {
        var selectorId = document.querySelector('.mdl-layout');
        selectorId.MaterialLayout.toggleDrawer();
    }


    return (
        <div className="big-content">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content=
                    "width=device-width,initial-scale=1" />
                <meta name="author" content="Preveen Raj" />
                <title>Preveen Raj | FullStack Developer</title>
                <link rel="icon" href="https://firebasestorage.googleapis.com/v0/b/portfolio-bb9f1.appspot.com/o/images%2Ffavicon.ico?alt=media&token=8fcb87a8-38c3-4ece-bfbc-59aedd0bd427" />
                <meta name="title" content=
                    "Preveen Raj | FullStack Developer" />
                <meta name="description" content=
                    "It takes knowledge, smart work, and dedication to make great ideas come to life. I have been helping make great ideas happen for the past few years and am always ready for new challenges." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://preveenraj.com/" />
                <meta property="og:title" content=
                    "Preveen Raj | FullStack Developer" />
                <meta property="og:description" content=
                    "It takes knowledge, smart work, and dedication to make great ideas come to life. I have been helping make great ideas happen for the past few years and am always ready for new challenges." />
                <meta property="og:image" content=
                    "https://firebasestorage.googleapis.com/v0/b/portfolio-bb9f1.appspot.com/o/images%2Fprcycle.png?alt=media&token=c843705a-9eab-424a-aa64-ab491eecde59"
                />
                <meta property="twitter:card" content="summary_large_image"
                />
                <meta property="twitter:url" content="https://preveenraj.com/"
                />
                <meta property="twitter:title" content=
                    "Preveen Raj | FullStack Developer"
                />
                <meta property="twitter:description" content=
                    "It takes knowledge, smart work, and dedication to make great ideas come to life. I have been helping make great ideas happen for the past few years and am always ready for new challenges." />
                <meta property="twitter:image" content=
                    "https://firebasestorage.googleapis.com/v0/b/portfolio-bb9f1.appspot.com/o/images%2Fprcycle.png?alt=media&token=c843705a-9eab-424a-aa64-ab491eecde59"
                />
                <script>{`
                !function (f, b, e, v, n, t, s) {
                    if(f.fbq) return; n = f.fbq = function () {
                    n.callMethod ?
                        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                };
                if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
                n.queue = []; t = b.createElement(e); t.async = !0;
                    t.src = v; s = b.getElementsByTagName(e)[0];
                     s.parentNode.insertBefore(t, s)
                    }(window, document, 'script',
                     'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '2054478488020628');
                    fbq('track', 'PageView');`}
                </script>
            </Helmet>
            <Layout>
                <Header transparent={true} waterfall={true} title={<Link style={{ textDecoration: 'none', color: 'black', fontWeight: "bolder" }} to="/">Preveen Raj</Link>}>
                    <Navigation className="header-nav">
                        <Link style={{ color: 'black', fontWeight: "bold" }} to="/aboutme">About Me</Link>
                        <Link style={{ color: 'black', fontWeight: "bold" }} to="/contact">Contact</Link>
                        <Link style={{ color: 'black', fontWeight: "bold" }} to="/projects">Projects</Link>
                        <Link style={{ color: 'black', fontWeight: "bold" }} to="/resume">Resume</Link>
                    </Navigation>
                </Header>
                <Drawer title={<Link style={{ textDecoration: 'none', color: 'black', fontWeight: "bolder" }} to="/" onClick={() => hideToggle()}>Preveen Raj</Link>} >
                    <Navigation className="header-nav">
                        <Link to="/aboutme" onClick={() => hideToggle()}>About Me</Link>
                        <Link to="/contact" onClick={() => hideToggle()}>Contact</Link>
                        <Link to="/projects" onClick={() => hideToggle()}>Projects</Link>
                        <Link to="/resume" onClick={() => hideToggle()}>Resume</Link>
                    </Navigation>
                </Drawer>
                <Content>
                    <div className="page-content" />
                    <Main />
                </Content>
            </Layout>
        </div>
    );
}

export default App;
