import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl'

class Education extends Component {
    width = this.props.width || '120px';
    height = this.props.height || '60px';
    opacity = this.props.opacity;
    render() {
        return (
            <Grid>
                <Cell col={4}>
                    <p>{this.props.startTime} - {this.props.endTime}</p>
                </Cell>
                <Cell col={8}>
                    <h4 style={{ marginTop: '0px' }}>{this.props.institutionName}</h4>
                    <p>{this.props.institutionDescription}</p>
                    <a href={this.props.institutionUrl} target="_blank" rel="noopener noreferrer" ><img alt={this.props.institutionName} style={{ width: this.width, height: this.height, opacity: this.opacity }} src={this.props.institutionImageUrl} /></a>
                </Cell>
            </Grid>
        );
    }
}

export default Education;