import React, { Component } from 'react'
import { Tabs, Tab, Grid, Cell } from 'react-mdl'
import { Card, CardTitle, CardActions, CardText, Button } from 'react-mdl'

class Projects extends Component {

  constructor(props) {
    super(props)

    this.state = {
      activeTab: 0
    }
  }

  generateCard = ({ title, description, projectBanner, github, playstore, livedemo }) => {
    return (
      // bottom right 15% no-repeat #46B6AC
      <Cell col={4}>
        <Card shadow={3} style={{ width: '450', margin: 'auto', height: '320px' }}>
          <CardTitle style={{
            color: '#fff', height: '176px',
            background: `url(${projectBanner}) center/cover no-repeat `
          }}>
          </CardTitle>
          <CardText>
            <b>{title}</b><br></br><br></br>
            {description}
          </CardText>
          <CardActions border>
            {github && <Button href={github} target="_blank" colored>Github</Button>}
            {playstore && <Button href={playstore} target="_blank" colored>Play Store</Button>}
            {livedemo && <Button href={livedemo} target="_blank" colored>Live Demo</Button>}
          </CardActions>
          {/* <CardMenu style={{color: '#fff'}}>
       <IconButton name="share"/>
     </CardMenu> */}
        </Card>
      </Cell >)
  }

  toggleCategories() {
    const { activeTab } = this.state;
    switch (activeTab) {
      case 0: return (
        <Grid>
          {/* Android*/}
          {this.generateCard({
            title: 'goldRate',
            description: 'The app obtains the daily gold price per gram from the web. The price will be available each day after 10:30 am.',
            projectBanner: 'https://lh3.googleusercontent.com/oCThVJb9tgEnYi8cpgMHeYokJcYDCpdGmtsBXxTaYeYtXp6x2S7CRBhGw0XjQTHgBw=s360-rw',
            github: 'https://github.com/preveenraj/GoldRate',
            playstore: 'https://play.google.com/store/apps/details?id=com.techrush.goldrate&hl=en'
          })}
          {this.generateCard({
            title: 'Cafe Billing Web App',
            description: 'A clean console for the cafe manager to take in orders from the menu, print it right away and generate reports.',
            projectBanner: 'https://firebasestorage.googleapis.com/v0/b/since97web.appspot.com/o/menu%2Fmenudesigntitle.jpg?alt=media&token=f16e6e08-cc5a-4c19-ba38-c6b0a554ee7a',
            livedemo: 'https://showcase-cafesince97.web.app/'
          })}
          {this.generateCard({
            title: 'Gym Website',
            description: 'A portal where users can register for gym membership, get location info on embedded googlemaps and calculate BMI.',
            projectBanner: 'https://friendsfitnesscentre-d2638.web.app/images/homenew.jpg',
            livedemo: 'https://friendsfitnesscenter.com/'
          })}
        </Grid>
      );
      case 1: return (
        <Grid>
          {/* Android*/}
          {this.generateCard({
            title: 'goldRate',
            description: 'The app obtains the daily gold price per gram from the web. The price will be available each day after 10:30 am.',
            projectBanner: 'https://lh3.googleusercontent.com/oCThVJb9tgEnYi8cpgMHeYokJcYDCpdGmtsBXxTaYeYtXp6x2S7CRBhGw0XjQTHgBw=s360-rw',
            github: 'https://github.com/preveenraj/GoldRate',
            playstore: 'https://play.google.com/store/apps/details?id=com.techrush.goldrate&hl=en'
          })}
          {/* <Cell col={4}>
            <Card shadow={3} style={{ width: '450', height: '320px', background: 'url(https://www.vippng.com/png/detail/250-2501976_project-management-images-png.png) center / cover', margin: 'auto' }}>
              <CardTitle expand />
              <CardActions style={{ height: '52px', padding: '16px', background: 'rgba(0,0,0,0.2)' }}>
                <span style={{ color: '#fff', fontSize: '14px', fontWeight: '500' }}>
                  More projects in the making...
        </span>
              </CardActions>
            </Card>
          </Cell> */}
        </Grid>
      );
      case 2: return (
        <Grid>
          {this.generateCard({
            title: 'Cafe Billing Web App',
            description: 'A clean console for the cafe manager to take in orders from the menu, print it right away and generate reports.',
            projectBanner: 'https://firebasestorage.googleapis.com/v0/b/since97web.appspot.com/o/menu%2Fmenudesigntitle.jpg?alt=media&token=f16e6e08-cc5a-4c19-ba38-c6b0a554ee7a',
            livedemo: 'https://showcase-cafesince97.web.app/'
          })}
        </Grid>
      );
      case 3: return (
        <Grid>
          {this.generateCard({
            title: 'Gym Website',
            description: 'A portal where users can register for gym membership, get location info on embedded googlemaps and calculate BMI.',
            projectBanner: 'https://friendsfitnesscentre-d2638.web.app/images/homenew.jpg',
            livedemo: 'https://friendsfitnesscentre-d2638.web.app/'
          })}
        </Grid>
      );
      default: return (<>

      </>);
    }
  }
  render() {
    return (
      <div className="category-tabs">
        <Tabs activeTab={this.state.activeTab} onChange={(tabId) => this.setState({ activeTab: tabId })} ripple>
          {/* <Tab>React</Tab> */}
          <Tab>All</Tab>
          <Tab>Android</Tab>
          <Tab>Angular</Tab>
          <Tab>Vanilla</Tab>
        </Tabs>

        <Grid>
          <Cell col={12}>
            <div className="content">{this.toggleCategories()}</div>
          </Cell>
        </Grid>
      </div>
    )
  }
}

export default Projects;

