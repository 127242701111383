import React, { Component } from 'react'
import Terminal from '../Terminal'
import { Chip } from 'react-mdl';



class AboutMe extends Component {
  render() {
    return (
      <div style={{margin: '10px 10% 10px 10%' }}>
        <h1>About me</h1>
        <Chip style={{marginBottom: '10px'}}>Type *help* for more information</Chip>
        <Terminal/>
      </div>
    )
  }
}

export default AboutMe;