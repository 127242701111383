import React, { Component } from 'react'
import { Grid, Cell } from 'react-mdl';
import profilePhoto from '../assets/images/profile.png';

class Landing extends Component {
  render() {
    return (
      <div style={{width: '100%', margin: 'auto', height: "100vh"}}>
    <Grid className="landing-grid">
        <Cell className="banner" col={12}>
          <img 
          src={profilePhoto}
          alt="profile"
          className="avatar-img"
          />
          <div className="banner-text">
            <h1> Full Stack Web/App Developer</h1>
            
            <hr/> 
            
            <p> React | Angular | Nodejs | Hapi | Postgresql | Android </p>
            <div className="social-links">
            
            {/* Github */}
            <a href="https://github.com/preveenraj"  rel="noopener noreferrer" target="_blank">
              <i className="fa fa-github-square" aria-hidden="true"></i>
            </a>
            
            {/* LinkedIn */}
            <a href="https://www.linkedin.com/in/preveenraj"  rel="noopener noreferrer" target="_blank">
              <i className="fa fa-linkedin-square" aria-hidden="true"></i>
            </a>
            
            {/* Facebook */}
            <a href="https://www.facebook.com/preveen.raj.140"  rel="noopener noreferrer" target="_blank">
              <i className="fa fa-facebook-square" aria-hidden="true"></i>
            </a>
            
            {/* Instagram */}
            <a href="https://www.instagram.com/preveen.raj"  rel="noopener noreferrer" target="_blank">
              <i className="fa fa-instagram" aria-hidden="true"></i>
            </a>
              
            </div>
          </div>
        </Cell>
    </Grid>
   </div>
    )
  }
}

export default Landing;

