import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Contact from './contact';
import LandingPage from './landingpage';
import Projects from './projects';
import Resume from './resume';
import AboutMe from './aboutme';

const Main = () => {
  return (
   <Switch>
     <Route exact path="/" component={LandingPage} />
     <Route exact path="/aboutme" component={AboutMe} />
     <Route exact path="/contact" component={Contact} />
     <Route exact path="/projects" component={Projects} />
     <Route exact path="/resume" component={Resume} />
   </Switch>
  );
}

export default Main;


