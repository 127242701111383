import React, { Component } from 'react'
import { Grid, Cell } from 'react-mdl'

class Contact extends Component {

  avatarArrays = [
    "",
    "https://firebasestorage.googleapis.com/v0/b/portfolio-bb9f1.appspot.com/o/images%2Fprbang.png?alt=media&token=0a897d3e-715e-4b46-b5d1-e30aabf57e52",
    "https://firebasestorage.googleapis.com/v0/b/portfolio-bb9f1.appspot.com/o/images%2Fprchill.png?alt=media&token=b5578b04-562c-4abe-988b-3324823b3dbd",
    "https://firebasestorage.googleapis.com/v0/b/portfolio-bb9f1.appspot.com/o/images%2Fprcycle.png?alt=media&token=c843705a-9eab-424a-aa64-ab491eecde59",
    "https://firebasestorage.googleapis.com/v0/b/portfolio-bb9f1.appspot.com/o/images%2Fprmeditate.png?alt=media&token=36b00c19-3486-4922-9a0a-2224219ea2a2",
    "https://firebasestorage.googleapis.com/v0/b/portfolio-bb9f1.appspot.com/o/images%2Fprrelax.png?alt=media&token=5e05bc37-9ebe-4c27-ab38-163a09472160",
    "https://firebasestorage.googleapis.com/v0/b/portfolio-bb9f1.appspot.com/o/images%2Fprride.png?alt=media&token=7f16a854-c0c6-4a65-bfe0-5fd269e59436",
    ""
  ]

  render() {

    return (
      <div className="contact-body">
        <Grid className="contact-grid">
          <Cell col={6}>
            <h2>Preveen Raj</h2>
            <img
              src={this.avatarArrays[Math.floor(Math.random() * 6) + 1]}
              alt="avatar"
              style={{ height: '250px' }}
            />
            <p style={{ width: '75%', margin: 'auto', paddingTop: '2em' }}>
              The greatest goal in life is not reaching the highest point, not being the richest person in the world, nor being an intelligent emotionless creature.
              Its achieving the state of mind where we actually become a human being. To help, to coordinate and especially to smile.
                </p>
          </Cell>
          <Cell col={6}>
            <h2>Contact me</h2>
            <hr />
            <div className="contact-list">
              <a target="_blank" rel="noopener noreferrer" href="https://mail.google.com/mail/?view=cm&fs=1&to=97preveenraj@gmail.com">
                <i style={{ margin: "auto" }} className="fa fa-envelope" aria-hidden="true" />
              </a>
            </div>

          </Cell>
        </Grid>
      </div>
    )
  }
}

export default Contact;

