import React, { Component } from 'react'
import { Grid, Cell } from 'react-mdl'
import Education from './education'
import Experience from './experience'
import Skills from './skills'

class Resume extends Component {
  render() {
    return (
      <div>
        <Grid style={{ padding: '0', margin: '0' }}>
          <Cell col={4}>
            <div style={{ textAlign: 'center' }}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/portfolio-bb9f1.appspot.com/o/images%2Fprcycle.png?alt=media&token=c843705a-9eab-424a-aa64-ab491eecde59"
                alt="avatar"
                style={{ height: '200px' }}
              />
            </div>
            <h2 style={{ paddingTop: '2em' }}>Preveen Raj</h2>
            <h4 style={{ color: 'grey' }}>Product Developer</h4>
            <hr style={{ borderTop: '3px solid #833fb2', width: '50%' }} />
            <p>
              It takes knowledge, smart work, and dedication to make great ideas come to life. I have been helping make great ideas happen for the past few years and am always ready for new challenges.  </p>
            <hr style={{ borderTop: '3px solid #833fb2', width: '50%' }} />
            <h5>Address</h5>
            <p>South of South Asia, <br /> In a small galaxy,   <br />Somewhere in the universe  </p>
            <h5>Email</h5>
            <p>97preveenraj@gmail.com</p>
            <p><a href="https://www.linkedin.com/in/preveenraj" rel="noopener noreferrer" target="_blank">LinkedIn</a></p>
            <hr style={{ borderTop: '3px solid #833fb2', width: '50%' }} />
          </Cell>
          <Cell className="resume-right-col" col={8}>

            <h2>Experience</h2>

            <Experience
              startTime={'Dec 2020'}
              jobName="Software Engineer"
              jobDescription=""
              companyUrl="https://bigbinary.com"
              companyImageUrl="https://firebasestorage.googleapis.com/v0/b/portfolio-bb9f1.appspot.com/o/images%2Fbb.png?alt=media&token=8b5c7794-4b62-4fbf-a2af-07b55e8455ff"
            />

            <Experience
              startTime={'Nov 2019'}
              endTime={'Nov 2020'}
              jobName="Product Developer"
              jobDescription=""
              companyUrl="https://surveysparrow.com"
              companyImageUrl="http://static.surveysparrow.com/site/assets/surveysparrow-logo-lg.png"
            />

            <Experience
              startTime={'Jun 2019'}
              endTime={'Nov 2019'}
              jobName="Programmer Analyst Trainee"
              jobDescription=""
              companyUrl="https://cognizant.com"
              companyImageUrl="https://www.cognizant.com/content/dam/cognizant_foundation/Dotcomimage/COG-Logo-White.svg"
              opacity={0.7}
            />

            <hr style={{ borderTop: '3px solid #e22947' }} />

            <h2>Education</h2>

            <Education
              startTime={2015}
              endTime={2019}
              institutionName="Rajagiri School of Engineering and Technology"
              institutionDescription=""
              institutionUrl="https://www.rajagiritech.ac.in/Home/Index.asp"
              institutionImageUrl="https://res.cloudinary.com/bizzaboprod/image/upload/c_crop,g_custom,f_auto/v1567545097/jiyuhqfb6mkffsen6llp.png"
            />

            <Education
              startTime={2000}
              endTime={2015}
              institutionName="Mary Mount Public School & Junior College"
              institutionDescription=""
              institutionUrl="http://www.marymountpublicschool.org"
              institutionImageUrl="https://firebasestorage.googleapis.com/v0/b/portfolio-bb9f1.appspot.com/o/images%2Fmarymount.png?alt=media&token=47f41bb8-f475-48a6-83c8-815487f1462f"
              opacity='0.5'
              width='40px'
              height='40px'

            />

            <hr style={{ borderTop: '3px solid #e22947' }} />

            <h2>Skills</h2>

            <Skills
              skill="javascript"
              progress={87}
            />

            <Skills
              skill="react"
              progress={73}
            />

            <Skills
              skill="android"
              progress={85}
            />

            <Skills
              skill="nodejs"
              progress={78}
            />

            <Skills
              skill="angular"
              progress={58}
            />

          </Cell>
        </Grid>
      </div>
    )
  }
}

export default Resume;

