import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl'

class Experience extends Component {
    render() {
        return (
            <Grid>
                <Cell col={4}>
                    <p>{this.props.startTime} - {this.props.endTime || 'Present'}</p>
                </Cell>
                <Cell col={8}>
                    <h4 style={{ marginTop: '0px' }}>{this.props.jobName}</h4>
                    <p>{this.props.jobDescription}</p>
                    <a href={this.props.companyUrl} target="_blank" rel="noopener noreferrer" ><img alt={this.props.jobName} style={{ width: '120px', height: '30px', opacity: this.props.opacity }} src={this.props.companyImageUrl} /></a>
                </Cell>
            </Grid>
        );
    }
}

export default Experience;